import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private _cookie: CookieService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let access = sessionStorage.getItem(`${window.location.hostname}`);

        if (access) { return true; }
        else {
            this._router.navigate(['/auth']);
            return false;
        }
    }
}