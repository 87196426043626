import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { Auth } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MessagingService {
    notificationToken: string = '';
    public req:any = {};
    public permission: Permission;

    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging, private _apiService: Auth, 
        private _toaster: ToastrService) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
        this.permission = this.isSupported() ? 'default' : 'denied';
    }

    public isSupported(): boolean {
        return 'Notification' in window;
    }

    requestPermission() {
        this.req = JSON.parse(atob(sessionStorage.getItem(`${window.location.hostname}`)));
        
        this.angularFireMessaging.requestToken.subscribe(
            (token: any) => {
                if(sessionStorage.getItem('FCMtoken') == null || sessionStorage.getItem('FCMtoken') == undefined || sessionStorage.getItem('FCMtoken') == '') {
                    this.tokenUpdate(token);
                }
            },
            (err) => {
                this._toaster.error("", 'Unable to get permission to notify.');
            }
        );
        let self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function(status) {
                return self.permission = status;
            });
        }
    }

    create(title: string, options ? : PushNotification): any {
        let self = this;
        return new Observable(function(obs) {
            if (!('Notification' in window)) {
                console.log('Notifications are not available in this environment');
                obs.complete();
            }
            if (self.permission !== 'granted') {
                console.log("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            let _notify = new Notification(title, options);
            _notify.onshow = function(e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclick = function(e) {
                return obs.next({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onerror = function(e) {
                return obs.error({
                    notification: _notify,
                    event: e
                });
            };
            _notify.onclose = function() {
                return obs.complete();
            };
        });
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
                this.generateNotification(payload)
        })
    }

    generateNotification(source): void {
        let self = this;
        let notification = source.notification;
        let options = {
            body: notification.body,
            icon: notification.icon,
        };
        let notify = self.create(notification.title, options).subscribe();
    }

    tokenUpdate(token: any) {
        let condition = { fcmToken: token };
        let req = this.req;
        condition['user_type'] = req.user_type;
        condition['user_id'] = req.user_id;

        this._apiService.updateUserFCMToken(condition).subscribe(
          (res: any) => {
                console.log(res)
                if(res.success) {
                    sessionStorage.setItem("FCMtoken", token);
                }
          },
          (err) => { console.error(err) });
    }
}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
    body ? : string;
    icon ? : string;
    tag ? : string;
    data ? : any;
    renotify ? : boolean;
    silent ? : boolean;
    sound ? : string;
    noscreen ? : boolean;
    sticky ? : boolean;
    dir ? : 'auto' | 'ltr' | 'rtl';
    lang ? : string;
    vibrate ? : number[];
}

/* import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class FirebaseNotification {

    currentMessage = new BehaviorSubject(null);
    notificationToken: string;

    constructor(private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }

    requestPermission() {
        this.angularFireMessaging.getToken.subscribe(
            (token) => {
                this.notificationToken = token;
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
            })
    }
} */
