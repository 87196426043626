import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { config } from '../_helper'

@Injectable({ providedIn: 'root' })

export class Admin {

    constructor(private http: HttpClient) { }

    public _getUserprofile(req) {
        return this.http.post(`${config.baseUrl}/get_profile`, req);
    }

    public _editprofile(req) {
        return this.http.post(`${config.baseUrl}/update_profile`, req);
    }

    public changePassword(req) {
        return this.http.post(`${config.baseUrl}/changePassword`, req);
    }

    public getToken() {
        /* if user login  */
        if (sessionStorage.getItem(`${window.location.hostname}`)) {
            const tokenTemp: any = sessionStorage.getItem(`${window.location.hostname}`);
            const token = JSON.parse(atob(tokenTemp)).token;
            // console.log(token);

            return token;
        }
        /* if user login  */

        /* if not login */
        return ''
        /* if not login */
    }

    // Report
    public _getMixedCount(req) {
        return this.http.post(`${config.baseUrl}/getMixedCount`, req);
    }

    public _new_getReport(req) {
        return this.http.post(`${config.baseUrl}/new_getReport`, req);
    }

    public _report_mixed_count(req) {
        return this.http.post(`${config.baseUrl}/report_mixed_count`, req);
    }

    public _new_report_mixed_count(req) {
        return this.http.post(`${config.baseUrl}/new_report_mixed_count`, req);
    }

    public _new_getReport_menagement(req) {
        return this.http.post(`${config.baseUrl}/new_getReport_menagement`, req);
    }

    // Owner

    public _getownerlist_for_admin(req) {
        return this.http.post(`${config.baseUrl}/getownerlist_for_admin`, req);
    }

    public _getownerlist(req) {
        return this.http.post(`${config.baseUrl}/getownerlist`, req);
    }

    public _changeownerstatus(req) {
        return this.http.post(`${config.baseUrl}/changeownerstatus`, req);
    }
    
    public _getallowner(req) {
        return this.http.post(`${config.baseUrl}/getallowner`, req);
    }
    
    public _updatecard(req) {
        return this.http.post(`${config.baseUrl}/updatecard`, req);
    }

    // Business
    public _getallbusiness(req) {
        return this.http.post(`${config.baseUrl}/getallbusiness_admin`, req);
    }
    
    public _getownerbusiness(req) {
        return this.http.post(`${config.baseUrl}/getownerbusiness`, req);
    }

    public _changebusinessstatus(req) {
        return this.http.post(`${config.baseUrl}/changebusinessstatus`, req);
    }
    
    // content
    public _cmsinsert(req) {
        return this.http.post(`${config.baseUrl}/cmsinsert`, req);
    }

    public _getcmslist(req) {
        return this.http.post(`${config.baseUrl}/getcmslist`, req);
    }

    public _cmsupdate(req) {
        return this.http.post(`${config.baseUrl}/cmsupdate`, req);
    }

    public _changecmsstatus(req) {
        return this.http.post(`${config.baseUrl}/changecmsstatus`, req);
    }

    // Site-configuration
    public _configinsert(req) {
        return this.http.post(`${config.baseUrl}/configinsert`, req);
    }

    public _getconfiglist(req) {
        return this.http.post(`${config.baseUrl}/getconfiglist`, req);
    }

    public _configupdate(req) {
        return this.http.post(`${config.baseUrl}/configupdate`, req);
    }

    public _changeconfigstatus(req) {
        return this.http.post(`${config.baseUrl}/changeconfigstatus`, req);
    }
    
    // Event
    public _addevent(req) {
        return this.http.post(`${config.baseUrl}/addevent`, req);
    }

    public _event_list(req) {
        return this.http.post(`${config.baseUrl}/event_list`, req);
    }

    public _changeeventstatus(req) {
        return this.http.post(`${config.baseUrl}/changeeventstatus`, req);
    }

    public _updateevent(req) {
        return this.http.post(`${config.baseUrl}/updateevent`, req);
    }

    // Complains
    public _getallComplaints(req) {
        return this.http.post(`${config.baseUrl}/getallComplaints`, req);
    }

    public _viewComplaints(req) {
        return this.http.post(`${config.baseUrl}/viewComplaints`, req);
    }

    // Branch
    public _getallownerbranch(req) {
        return this.http.post(`${config.baseUrl}/getallownerbranch`, req);
    }

    public _changebranchstatus(req) {
        return this.http.post(`${config.baseUrl}/changebranchstatus`, req);
    }

    // Member
    public _getownermember(req) {
        return this.http.post(`${config.baseUrl}/getownermember`, req);
    }

    public _changememberstatus(req) {
        return this.http.post(`${config.baseUrl}/changememberstatus`, req);
    }

    // Offer
    public _getallofferlist_admin(req) {
        return this.http.post(`${config.baseUrl}/getallofferlist_admin`, req);
    }

    public getofferdetails(req) {
        return this.http.post(`${config.baseUrl}/getofferdetails`, req);
    }
    
    public _viewofferdetails(req) {
        return this.http.post(`${config.baseUrl}/viewofferdetails`, req);
    }
    
    public _getofferdetails_admin(req) {
        return this.http.post(`${config.baseUrl}/getofferdetails_admin`, req);
    }
    
    public _chageofferstatus(req) {
        return this.http.post(`${config.baseUrl}/chageofferstatus`, req);
    }

    // Notification
    public _addNotification(req) {
        return this.http.post(`${config.baseUrl}/addNotification`, req);
    }

    public _getNotificationdesk(req) {
        return this.http.post(`${config.baseUrl}/getNotificationdesk`, req);
    }

    // Transcation
    public _getalltranscation(req) {
        return this.http.post(`${config.baseUrl}/getalltranscation`, req);
    }

    // Board-member
    public _addavmmember(req) {
        return this.http.post(`${config.baseUrl}/addavmmember`, req);
    }

    public _updateavmmember(req) {
        return this.http.post(`${config.baseUrl}/updateavmmember`, req);
    }

    public _getavmmember(req) {
        return this.http.post(`${config.baseUrl}/getavmmember`, req);
    }

    public _changeb_memberstatus(req) {
        return this.http.post(`${config.baseUrl}/changeb_memberstatus`, req);
    }

    /** LOGOUT */
    public adminlogout(req) {
        return this.http.post(`${config.baseUrl}/logout`, req);
    }
}