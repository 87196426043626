import { Injectable } from '@angular/core';
import * as cryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })

export class Crypto {
    key: string = "rswna0hu8t"
    constructor() { }

    /* encrypt data */
    public encryptData(data) {
        if (data) {
            // console.log(btoa(data));
            return btoa(JSON.stringify(data));
            // return cryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
            // return Buffer.from(JSON.stringify(data)).toString('base64');
        }
        else console.log("data not available to process");

    }

    /* decrypt data */
    public decryptData(data) {
        if (data) {
            return JSON.parse(atob(data));
            // return JSON.parse(cryptoJS.AES.decrypt(data, this.key).toString(cryptoJS.enc.Utf8));
            // return JSON.parse(Buffer.from(data, 'base64').toString('ascii'));
        }
        else console.log("data not available to process");

    }

}