// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBx8yNJvRoRxrGVYMBr7zYUCiVkrRR8aps",
    authDomain: "avm-federation.firebaseapp.com",
    projectId: "avm-federation",
    storageBucket: "avm-federation.appspot.com",
    messagingSenderId: "170334980757",
    appId: "1:170334980757:web:b6a1e24395a5ea2780299c",
    measurementId: "G-4D5Y90DS9F"
  },
  appVersion: 'v717demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
