import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Admin, Crypto } from '../_service';

@Injectable()
export class tokenInterceptor implements HttpInterceptor {

    constructor(private _apiService: Admin, private _cryptoJs: Crypto) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.body instanceof FormData) {
            request = request.clone({
             
                setHeaders: { Authorization: `Bearer ${this._apiService.getToken()}`, },
            });
        }
        else {
            request = request.clone({
            
                setHeaders: { Authorization: `Bearer ${this._apiService.getToken()}`, },
                body: { info: request.body ? this._cryptoJs.encryptData(request.body) : request.body }

            });
        }
        return next.handle(request);
    }
}