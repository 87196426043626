export let config;

if (window.location.hostname === "localhost") {
    config = {
        baseUrl: 'http://localhost:6452/avm',
        imageUrl: 'http://localhost:6452/',
        scoketUrl: 'http://localhost:6452/',
    }
} else if (window.location.hostname === "avm.xceltec.in") {
    config = {
        baseUrl: 'https://avm.xceltec.in:3001/avm',
        imageUrl: 'https://avm.xceltec.in:3001/',
        scoketUrl: 'https://avm.xceltec.in:3001/',
    }
} else {
    config = {
        baseUrl: 'https://avmtraders.masglobalservices.com:6452/avm',
        imageUrl: 'https://avmtraders.masglobalservices.com:6452/',
        scoketUrl: 'https://avmtraders.masglobalservices.com:6452/',
    }
}