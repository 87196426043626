import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../_helper'

@Injectable({ providedIn: 'root' })

export class Auth {

    constructor(private http: HttpClient) { }

    public _login(req: any) {
        return this.http.post(`${config.baseUrl}/login`, req)
    }
   
    public _forgetpassword(req: any) {
        return this.http.post(`${config.baseUrl}/forgetPassword`, req)
    }

    public _resetpassword(req: any) {
        return this.http.post(`${config.baseUrl}/resetPassword`, req);
    }

    public _changePassword(req: any) {
        return this.http.post(`${config.baseUrl}/changePassword`, req);
    }
    
    public _otp(req: any) {
        return this.http.post(`${config.baseUrl}/email_otp`, req)
    }
    
    public _otpverify(req: any) {
        return this.http.post(`${config.baseUrl}/verify_email_otp`, req)
    }

    public updateUserFCMToken(req){
        return this.http.post(`${config.baseUrl}/updateUserFCMToken`, req);
    }

    public _getcmsdata(req){
        return this.http.post(`${config.baseUrl}/getcmsdata`, req);
    }
}